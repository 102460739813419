import React, { useMemo } from 'react'
// import get from 'tcweb-material-components/core/poly/get'
// import { makeStyles } from '@material-ui/core'
// import Typography from '@material-ui/core/Typography'
// import Grid from '@material-ui/core/Grid'
// import { useTranslation } from 'react-i18next'

// import Button from 'tcweb-material-components/core/button'

// import { getLogoutUrl } from '../../services/xboxLive'

// const ERROR_PARENT_BLOCK = '8015DC05'

// const useClasses = makeStyles(theme => ({
//     description: { margin: '15px 0' },
//     buttonContainer: {
//         margin: '30px 0 30px -8px',
//         [theme.breakpoints.up('md')]: { width: '50%' }
//     }
// }))

const ErrorPage = props => {
    // const classes = useClasses()
    // const { t } = useTranslation()
    // const errorCode = get(props, 'location.state.errorCode') || 0
    // const errorMessage = useMemo(() => {
    //     const errorMessages = {
    //         [ERROR_PARENT_BLOCK]: t('The account owner has been prevented by a parent from accessing Xbox Live at this time.')
    //     }

    //     return errorMessages[errorCode.toString(16).toUpperCase()]
    // }, [errorCode, t])

    return (
        <>
            {/* <h1>{t('Xbox Live Account Error')}</h1>
            {!!errorMessage && <Typography className={classes.description}>{errorMessage}</Typography>}
            <Typography className={classes.description}>
                {t('Please sign in to xbox.com to resolve the problem, or sign out and sign in with a different account.')}
            </Typography>

            <Grid container spacing={2} classes={{ root: classes.buttonContainer }}>
                <Grid item xs={12} sm={6}>
                    <Button color='primary' variant='contained' title={t('Visit Xbox.com')} url='https://www.xbox.com/' fullWidth>
                        {t('Visit Xbox.com')}
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button
                        color='tertiary'
                        variant='contained'
                        title={t('Sign Out')}
                        url={getLogoutUrl(props.location.href)}
                        target='_self'
                        fullWidth
                    >
                        {t('Sign Out')}
                    </Button>
                </Grid>
            </Grid> */}
        </>
    )
}

export default ErrorPage
