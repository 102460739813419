import React from 'react'
import get from 'tcweb-material-components/core/poly/get'

import LayoutContent from '../components/layout/layoutContent'
import GenericError from '../components/common/error'
import AuthError from '../components/common/authError'

const ErrorPage = props => (
    <LayoutContent {...props} hasVerticalGutters>
        {get(props, 'location.state.errorType') === 'auth' ? <AuthError {...props} /> : <GenericError />}
    </LayoutContent>
)

export default ErrorPage
